<template>
  <div class="app-item app-content">
      <div class="top-title">
        <h4>部门管理</h4>
        <el-button type="primary" size="small" @click="dialogFormVisible = true">添加部门</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="部门名称" fixed>
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.name"
              placeholder="请输入内容 "
              v-if="showEdit[scope.$index]"
            ></el-input>
            <span v-if="!showEdit[scope.$index]">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="部门负责人" >
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.principle"
              placeholder="请输入内容 "
              v-if="showEdit[scope.$index]"
            ></el-input>
            <span v-if="!showEdit[scope.$index]">{{scope.row.principle}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="showEdit[scope.$index]" type="text" size="small" @click="updateDepartments(scope.$index, scope.row)">
              <span>保存</span>
            </el-button>
            <el-button v-if="!showEdit[scope.$index]" type="text" size="small" @click="updateDepartments(scope.$index, scope.row)">
              <span>编辑</span>
            </el-button>
            <!-- <el-button type="text" size="small">停用/启用</el-button> -->
            <el-button
              type="text"
              size="small"
              @click="deleteDepartments(scope.$index,tableData)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 添加部门 -->
      <el-dialog title="添加部门" :visible.sync="dialogFormVisible" class="dialog">
        <el-form label-width="100px">
          <el-form-item label="部门名称">
            <el-input auto-complete="off" placeholder="请输入部门名称" v-model="deptname"></el-input>
          </el-form-item>
          <el-form-item label="部门负责人">
            <el-input auto-complete="off" placeholder="请输入部门负责人" v-model="deptprinciple"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogFormVisible = false;addDepartments()">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogFormVisible: false,
      deptname: '',
      deptprinciple: '',
      tableData: [],
      showEdit: [], // 显示编辑框
      showBtn: []
    }
  },
  methods: {
    showData (index, obj) {
      console.log(index, obj)
      this.showEdit[index] = !this.showEdit[index]
    },
    saveData (index, obj) {
      console.log(index, obj)
    },
    // 获取部门列表
    getDepartments () {
      this.$store.dispatch('getDepartments').then(res => {
        this.tableData = res
        // this.$notify({
        //   title: "警告",
        //   message: res.message,
        //   type: "warning"
        // });
      })
    },
    // 增加部门信息
    addDepartments () {
      let obj = {
        name: this.deptname,
        principle: this.deptprinciple
      }
      this.$store.dispatch('addDepartments', obj).then(res => {
        if (res.code === 0) {
          this.getDepartments()
          this.$message({
            message: '添加成功',
            type: 'success'
          })
        }
      })
    },
    // 删除部门信息
    deleteDepartments (index, params) {
      let array = params.splice(index, 1)
      let id = array[0].id
      this.$store.dispatch('deleteDepartments', id).then(res => {
        if (res.code === 0) {
          // this.getDepartments();
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        }
      })
    },
    // 修改部门信息
    updateDepartments (index, row) {
      this.showEdit[index] = !this.showEdit[index]
      // this.showBtn[index] = !this.showBtn[index]

      if (!this.showEdit[index]) {
        this.$store.dispatch('updateDepartments', {
          id: row.id,
          name: row.name,
          principle: row.principle
        }).then(res => {
          if (res.code === 0) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          }
        })
      }
    }
  },
  mounted () {
    this.getDepartments()
  }
}
</script>
<style lang="less" scoped>
.app-item {
  height: 100%;
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dialog {
    text-align: center;
    .dialog-footer {
      text-align: center;
      margin-top: -10px;
      .el-button {
        padding: 12px 30px;
      }
    }
  }
}

</style>
